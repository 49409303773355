import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Cliniverse | Secure Remote Telemedicine"
        titleTemplate="%s"
        defaultTitle="Cliniverse | Secure Remote Telemedicine"
        description="Cliniverse offers secure and reliable remote telemedicine services, connecting you with healthcare professionals from anywhere."
        twitter={{
          handle: '@cliniverse',
          site: '@cliniverse',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          url: 'https://axonichealth.com/',
          images: [
            {
              url: 'https://axonichealth.com/wp-content/uploads/2024/04/logo-with-black-text-1.png',
              width: 2000,
              height: 1000,
              type: 'image/png',
            },
          ],
          site_name: 'Cliniverse',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#123456',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/livekit-apple-touch.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/livekit-safari-pinned-tab.svg',
            color: '#123456',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
